import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import Layout from "../components/layout";
import NavBar from '../components/index/navbar';
import Footer from '../components/footer';
import '../styles/main.scss';

const NotFoundPage = () => {
  return (
      <Layout>
        <NavBar />
          <div className='background-404'>    {/*Backorund Container */}
          <StaticImage
            className="background-404__image"
            height={4200}
            placeholder='none'
            src="../images/Fondo-página-404.png"
            alt='Laboratorio-404-img'
          />
            {/*Main content container*/}
            <div className='not_found'>       
              {/*Laboratorio Clínico button container*/}
              <div className="not-found-button">
                  <i className='fas fa-circle fa-xs'></i>
                  <p>Laboratorio Clínico G&S</p>
              </div>
              {/*Main message container - divides in two divs (one for "404" and the other fo "ups.."*/}
              <div className='message'>
                <p className='message-404'>404</p>
                <hr className='message-line'></hr>
                <div className='message-div'>
                    <p className='message-div-ups'>Ups...<br></br>La página no<br></br>ha sido encontrada</p>
                    <button className='message-div-button' style={{color: 'white', }}>                         
                        <i className="fa-solid fa-angle-left message-div-button-icon"></i>         {/*Button icon class comes from Font Awesome*/}
                        <Link to='/' className='button-go-back'>Regresar</Link>
                    </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
      </Layout>
  );
}

export default NotFoundPage